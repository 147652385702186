import { OpenAI } from "openai";

function withDefaultSystemMessage(message) {
  return `${message}
  Stay focus on the topic or something relevant.
  Ensure the conversation flows naturally and isn't repetitive.
  Keep the conversation going, make them dig deeper into topic and explore interesting things, to show as much useful information as possible.
  Keep responses short, ideally under 25 words, but no more than 35.`
}

export async function sendMessage(messages, {
  userSystemMessageContent,
  assistantSystemMessageContent,
  provider,
  openaiApiKey,
  groqApiKey,
}) {
  if (messages.length === 0) {
    throw Error('No messages!')
  }

  // get last 9 messages only and 3 first messages
  const truncatedMessages = truncate(messages, { tail: 9, head: 3 });

  const lastMessage = truncatedMessages[truncatedMessages.length - 1]
  const isUserTurn = lastMessage.role === 'assistant'

  const systemMessage = {
    role: 'system',
    content: isUserTurn ? withDefaultSystemMessage(userSystemMessageContent) : withDefaultSystemMessage(assistantSystemMessageContent),
  }

  let finalMessages = [
    systemMessage,
    ...truncatedMessages,
  ]


  if (isUserTurn) {
    // reverse user & assistant role
    finalMessages = finalMessages.map(m => {
      if (m.role === 'system') {
        return m
      }

      return {
        ...m,
        role: m.role === 'user' ? 'assistant' : 'user'
      }
    })
  }

  const openai = new OpenAI({
    baseURL: provider === 'groq' ? 'https://api.groq.com/openai/v1' : 'https://api.openai.com/v1',
    apiKey: provider === 'groq' ? groqApiKey : openaiApiKey,
    dangerouslyAllowBrowser: true
  });

  const chatCompletion = await openai.chat.completions.create({
    messages: finalMessages,
    model: provider === 'groq' ? "llama3-70b-8192" : "gpt-4o",
    temperature: 1.3
  });

  const response = chatCompletion.choices[0]?.message?.content || ""

  return {
    content: response,
    role: isUserTurn ? 'user' : 'assistant'
  }
};

function truncate(messages, { tail, head }) {
  const clonedMessages = [...messages]
  const lastMessages = clonedMessages.splice(-tail);
  const firstMessages = clonedMessages.splice(0, head);
  // if ((firstMessages.length + lastMessages.length) < messages.length) {
  //   firstMessages.push({
  //     role: 'user',
  //     content: 'these messages in middle are truncated...'
  //   })
  // }
  const truncatedMessages = [
    ...firstMessages,
    ...lastMessages
  ];
  return truncatedMessages;
}